import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

function SimpleDialog({ open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Basic Dialog</DialogTitle>
      {/* Add more dialog content here */}
    </Dialog>
  );
}

export default SimpleDialog