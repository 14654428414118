import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          We offer services ranging from initial consultation for those seeking to get into aviation, to designing 
            a structured game plan, executing on plan with quality instruction, learning proper procedures with 
            simulators or "chair flying", simualting ATC conversation to get you extroverted and confident when talking to ATC, 
            and just putting you on the fast track to checking off your requirements for your pilot
            certificate or advanced rating and getting you checkride ready.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
